import React, { useState, useEffect, useCallback } from "react";
import { getUserListApi } from "../../../services/UserService";
import { CancelRequest } from "../../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Pagination from "../../constant/Pagination";
import UserAddModal from "./AddUserModal";
import debounce from 'lodash.debounce';
import TableLoaderEffect from '../../constant/TableLoader';
import DebounceSearchInput from "../../constant/DebounceSearchInput";

const UserPage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const recordsPage = 25;

    const navigate = useNavigate();

    const fetchData = useCallback((page, query) => {
        setIsLoading(true);
        getUserListApi(page, recordsPage, query)
            .then((resp) => {
                setData(resp.data.data);
                setTotal(resp.data.total);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error.response?.data?.message || "Error fetching data");
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData(currentPage, searchQuery);
    }, [currentPage, searchQuery, fetchData]);

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const handleSearchChange = (value) => {
        if (value.length > 0) CancelRequest();
        setSearchQuery(value);
        setCurrentPage(1)
    };

    const handleAfterCreate = (newData) => {
        setData((prevData) => [newData, ...prevData]);
        setTotal((prevTotal) => prevTotal + 1);
    };

    const handleAfterUpdate = (updatedData) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === updatedData.id ? { ...item, ...updatedData } : item
            )
        );
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                <div className="d-flex justify-content-between text-wrap align-items-center p-3">
                                    <h4 className="heading mb-0">User List</h4>
                                    <div className='d-flex'>
                                        <div className='form-group me-3'>
                                        <DebounceSearchInput handleSearchChange={handleSearchChange} />
                                        </div>
                                        <UserAddModal handleAfterCreate={handleAfterCreate} />
                                    </div>
                                </div>

                                <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                    <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Designation</th>
                                                <th>Department</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? <TableLoaderEffect colLength={9} /> : data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span className="text-primary fw-bold c-pointer"
                                                            onClick={() => navigate(`/user/${item.id}`)}>{item.first_name} {item.last_name}</span>
                                                    </td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone_number}</td>
                                                    <td>{item.designation.name}</td>
                                                    <td>{item.department.name}</td>
                                                    <td>{item.status === "Active" ? <span className='badge light border-0 badge-success badge-sm'>Active</span> :
                                                        <span className='badge light border-0 badge-danger badge-sm'>Inactive</span>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                        currentPage={currentPage}
                                        recordsPage={recordsPage}
                                        dataLength={total}
                                        handlePagination={handlePagination}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default UserPage;
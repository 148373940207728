import React, { useState, useEffect, useCallback } from "react";
import { getVisitingLogListApi } from "../../../services/UserService";
import { CancelRequest } from "../../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { StaticSelectInput } from "../../constant/SelectInput";
import Pagination from "../../constant/Pagination";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";
import TableLoaderEffect from "../../constant/TableLoader";
import DebounceSearchInput from "../../constant/DebounceSearchInput";

const VisitingLogPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    start_date: "",
    end_date: "",
    status: "",
  });
  const [isClearAll, setIsClearAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const recordsPage = 25;

  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getVisitingLogListApi(
      currentPage,
      recordsPage,
      searchQuery,
      filter.start_date,
      filter.end_date,
      filter.status
    )
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response?.data?.message || "Error fetching data");
        setIsLoading(false);
      });
  }, [currentPage, searchQuery, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleResetDateFilter = () => {
    setFilter({
      ...filter,
      start_date: "",
      end_date: "",
    });
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setFilter({
      ...filter,
      start_date: start ? format(start, "dd-MM-yyyy") : "",
      end_date: end ? format(end, "dd-MM-yyyy") : "",
    });
  };

  const handleSelectChange = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsClearAll(false);
  };

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleSearchChange = (value) => {
    if (value.length > 0) CancelRequest();
    setSearchQuery(value);
    setCurrentPage(1);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <div className="d-flex justify-content-between text-wrap align-items-center p-3">
                  <h4 className="heading mb-0">Visiting Logs</h4>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <StaticSelectInput
                        optionsList={[
                          { label: "Entry", value: "Entry" },
                          { label: "Exit", value: "Exit" },
                        ]}
                        setParentInputValue={handleSelectChange}
                        name="status"
                        isClearable={true}
                        labelHint={false}
                        defaultValue={
                          filter.status
                            ? { label: filter.status, value: filter.status }
                            : null
                        }
                        id="status"
                        label="Status"
                        isSetEmpty={isClearAll}
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <Flatpickr
                        value={[filter.start_date, filter.end_date]}
                        onChange={handleDateChange}
                        options={{ mode: "range", dateFormat: "d-m-Y" }}
                        className="form-control custom-flatpickr w-100 p-2 mx-auto border border-gray-800 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2"
                        placeholder="Select date range"
                        style={{ fontSize: "0.75rem" }}
                      />
                      {filter.start_date && filter.end_date && (
                        <i
                          className="fas fa-times position-absolute"
                          style={{
                            right: "17%",
                            top: "21%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "#999999",
                          }}
                          onClick={handleResetDateFilter}
                        />
                      )}
                    </div>
                    <div className="col-md-4 form-group">
                      <DebounceSearchInput
                        handleSearchChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>

                <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                  <table
                    id="employees-tblwrapper"
                    className="table ItemsCheckboxSec dataTable no-footer mb-0"
                  >
                    <thead>
                      <tr>
                        <th>Gatepass No.</th>
                        <th>Visitor</th>
                        <th>Officer To Meet</th>
                        <th>Gate</th>
                        <th>Status</th>
                        <th>Datetime</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <TableLoaderEffect colLength={6} />
                      ) : (
                        data.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span
                                className="text-primary fw-bold c-pointer"
                                onClick={() =>
                                  navigate(`/visitor/${item.gatepass?.id}`)
                                }
                              >
                                {item.gatepass?.gatepass_number}
                              </span>
                            </td>
                            <td>
                              <div
                                className="d-flex flex-column c-pointer"
                                onClick={() =>
                                  navigate(`/visitor/${item.visitor?.id}`)
                                }
                              >
                                <strong>{item.visitor?.name}</strong>
                                <span>{item.visitor?.email}</span>
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-flex flex-column c-pointer"
                                onClick={() =>
                                  navigate(`/user/${item.officer_to_meet?.id}`)
                                }
                              >
                                <strong>{item.officer_to_meet?.name}</strong>
                                <span>{item.officer_to_meet?.email}</span>
                              </div>
                            </td>
                            <td>{item.gate?.name}</td>
                            <td>
                              {item.status ? (
                                <span className="badge light border-0 badge-success badge-sm">
                                  Entry
                                </span>
                              ) : (
                                <span className="badge light border-0 badge-danger badge-sm">
                                  Exit
                                </span>
                              )}
                            </td>
                            <td>{item.created_at}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>

                  {!isLoading && (
                    <Pagination
                      currentPage={currentPage}
                      recordsPage={recordsPage}
                      dataLength={total}
                      handlePagination={handlePagination}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitingLogPage;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Pagination from "../../../constant/Pagination";
import TableLoaderEffect from "../../../constant/TableLoader";
import { getVisitorGatepassApi } from "../../../../services/UserService";

const GatePassDetails = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const recordsPage = 25;
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getVisitorGatepassApi(currentPage, recordsPage, id);
        const resp_data = resp.data.data;
        setData(resp_data);
        setTotal(resp.data.total || 0); 
      } catch (error) {
        console.error("Error fetching gate pass details:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, recordsPage, id]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
};

  return (
    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
      <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
        <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
          <thead>
            <tr>
              <th>Gatepass Number</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoaderEffect colLength={6} />
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{item.gatepass_number}</td>
                  <td>{item.visiting_start_date}</td>
                  <td>{item.visiting_end_date}</td>
                  <td>
                    {item.status === "Approved" ? (
                      <span className="badge light border-0 badge-success badge-sm">
                        Approved
                      </span>
                    ) : item.status === "Pending" ? (
                      <span className="badge light border-0 badge-warning badge-sm">
                        Pending
                      </span>
                    ) : (
                      <span className="badge light border-0 badge-danger badge-sm">
                        Rejected
                      </span>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        {!isLoading && (
          <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
      />
        )}
      </div>
    </div>
  );
};

export default GatePassDetails;

import React, { useContext, useEffect, useState } from "react";
import OfficerMostVisitor from "./elements/OfficeMostVisitor";
import { connect } from "react-redux";
// import Visitor from "./elements/Visitor";
// import HighestVisitor from "./elements/HighestVisitor";
// import VisitorGateComparision from "./elements/VisitorGateComparision";
// import VisitorStatus from "./elements/VisitorStatus";
import VisitorGatepassCountWidget from "./VisitorDashbaord/GatepassCountCard";
import VisitorMostVisitCardWidget from "./VisitorDashbaord/VisitorMostVisiting";
const Home = ({ userRole }) => {
  return (
    <div className="container-fluid">
      {userRole === "Visitor" ? (
      <div>
        <VisitorGatepassCountWidget />
        <VisitorMostVisitCardWidget />
      </div>
      ) :  null}
      <div className="row mb-2">
        {/* <div className="col-xl-12">
          {userRole === "Visitor" ? (
            <div className="row mb-2">
          <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
          </div>
           ) : (
            <div className="row mb-2">
          <div className="col-md-3 col-sm-6">
          <VisitorStatus />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
          </div>
          )}  
          
          <div className="row mb-2">
          <div className="col-md-4 mb-2">
          <OfficerMostVisitor />
        </div>
        <div className="col-md-8">
          <HighestVisitor />
        </div>
          </div>
          <div className="row">
            <div className="col-12">

          <VisitorGateComparision />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "Visitor",
});

export default connect(mapStateToProps)(Home);

import React, { useState, useEffect, useCallback } from "react";
import { getVisitorGatepassCountApi } from "../../../../services/DashboardService";
import ReactApexChart from "react-apexcharts";
import { formatDateRange } from "../../../../utils";
import { Link } from "react-router-dom";
import FullscreenImage from "../../../constant/FullscreenImage";

class GatepassCountDonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: props.series,
      options: {
        chart: {
          type: "donut",
          width: 200,
        },
        colors: ["#3AC977", "#FF9F00", "#00a0e3"],
        labels: ["Approved", "Pending", "Total"],
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: 12,
                },
                value: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Arial",
                  fontWeight: "500",
                  offsetY: -17,
                },

                total: {
                  show: true,
                  fontSize: "11px",
                  fontWeight: "500",
                  fontFamily: "Arial",
                  label: "Approved",
                  color: "#000",

                  formatter: function (w) {
                    return w.globals.seriesTotals[0] || 0;
                  },
                },
              },
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div className="d-flex align-items-center">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width={200}
          height={250}
        />
      </div>
    );
  }
}

const VisitorGatepassCountWidget = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getVisitorGatepassCountApi();
      const result = response.data.results;
      setData(result);
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isLoading ? (
    <div>
      <h3>Loading...</h3>
    </div>
  ) : data === null ? (
    <div>
      <h3>Something went wrong ...🤕</h3>
    </div>
  ) : (
    <div className="row">
      <div className="col-xl-3 col-sm-6">
        <div className="card same-card">
          <div className="card-body d-flex align-items-center  py-2">
            {data.last_active_gatepass === null ? (
              <div>
                <h3 className="text-center">Gatepass Count </h3>
                <GatepassCountDonutChart
                  series={[
                    data.approved_count,
                    data.pending_count,
                    data.approved_count + data.pending_count,
                  ]}
                />
              </div>
            ) : (
              <div className="flex-fill">
                <h3 className="text-center text-success">Active Gatepass</h3>
                <div className="d-flex align-items-center justify-content-around">
                  <FullscreenImage
                    src={`${process.env.REACT_APP_BASE_URL}${data.last_active_gatepass.qrcode}`}
                    alt="QR Image"
                    thumbnailStyle={{
                      height: "100px",
                    }}
                    className="mx-2 border rounded"
                  />
                  <div className="ms-3">
                    <h4>
                      <i className="fa-solid fa-hashtag text-primary me-2"></i>{" "}
                      {data.last_active_gatepass.gatepass_number}
                    </h4>
                    <p>
                      <i class="fa-regular fa-calendar-days text-primary me-2"></i>
                      {formatDateRange(
                        data.last_active_gatepass.visiting_start_date,
                        data.last_active_gatepass.visiting_end_date
                      )}
                    </p>
                    <Link
                      className="btn btn-sm btn-outline-primary"
                      to={`/gatepass/${data.last_active_gatepass.id}`}
                    >
                      View Details <i className="fa-solid fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card bg-success">
          <div className="card-body">
            <h3 className="text-white text-center">Approved Gatepass</h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.approved_count}
            </h1>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card bg-warning">
          <div className="card-body">
            <h3 className="text-white text-center">Pending Gatepass</h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.pending_count}
            </h1>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card bg-primary">
          <div className="card-body">
            <h3 className="text-white text-center">Total Gatepass</h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.approved_count + data.pending_count}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorGatepassCountWidget;

export const dateFomatYMD = (dateObject) => {
  const isoString = dateObject.toISOString();
  return isoString.split("T")[0];

}


export const getInitialChar = (fullName) => {
  const nameArray = fullName.split(' ');
  // Get the first letter of the first name
  const firstNameInitial = nameArray[0].charAt(0);
  // Get the first letter of the last name (if available)
  const lastNameInitial = nameArray.length > 1 ? nameArray[nameArray.length - 1].charAt(0) : '';
  // Concatenate the initials
  const initials = firstNameInitial + lastNameInitial;
  return initials.toUpperCase(); // Convert to uppercase if needed
}

// Function to mask email
export function maskEmail(email) {
  let [username, domain] = email.split('@');
  // If the username has less than 3 characters, leave it unchanged.
  if (username.length <= 2) {
    return email;
  }

  // Mask the characters after the first two
  let maskedUsername = username.slice(0, 2) + '*'.repeat(username.length - 2);

  // Return the masked email
  return `${maskedUsername}@${domain}`;
}

// Function to mask phone number
export function maskPhoneNumber(phoneNumber) {
  // If the phone number is less than 6 characters, leave it unchanged
  if (phoneNumber.length !== 10) {
    return phoneNumber;
  }
  let maskedPhone = phoneNumber.slice(0, 2) + 'xxxx' + phoneNumber.slice(-4);
  return maskedPhone;
}
export const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
};


export function formatDate(dateString, gap = " ") {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  const parts = date.toLocaleDateString('en-US', options).split(' ');
  const formattedDate = `${parts[1]}${gap}${parts[0]}${gap}${parts[2]}`;

  return formattedDate;
}


export function formatDateTime(inputDate) {
  // Parse the input date
  if (!inputDate){
    return ""
  }
  const date = new Date(inputDate);
  // Get individual parts of the date and time
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Determine AM/PM and adjust hours
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 hours)

  // Return formatted date and time
  return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
}

export function formatDateRange(startDateStr, endDateStr) {
  // Helper function to format date as "DD MMM YYYY"
  const formatDate = (date, includeYear = true) => {
    const options = { day: 'numeric', month: 'short' };
    if (includeYear) options.year = 'numeric';
    // Using 'en-GB' to get "DD MMM YYYY" format
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  // Helper function to format date as "DD MMM" (no year)
  const formatDayMonth = date => {
    return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short' }).format(date);
  };

  // Convert strings to Date objects (assuming input format is YYYY-MM-DD)
  const start = new Date(startDateStr); // YYYY-MM-DD
  const end = new Date(endDateStr); // YYYY-MM-DD

  // Check if same year, month, and day
  if (start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth() &&
      start.getDate() === end.getDate()) {
    return formatDate(start); // Same day
  }

  // Check if same year and month but different days
  if (start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth()) {
    return `${start.getDate()} - ${end.getDate()} ${formatDate(start, false)} ${start.getFullYear()}`;
  }

  // Check if same year but different months
  if (start.getFullYear() === end.getFullYear()) {
    return `${formatDayMonth(start)} - ${formatDayMonth(end)} ${start.getFullYear()}`;
  }

  // Different years
  return `${formatDayMonth(start)} ${start.getFullYear()} - ${formatDayMonth(end)} ${end.getFullYear()}`;
}

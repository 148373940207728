import React, { useState } from "react";

const FullscreenImage = ({ src, alt, thumbnailStyle, className }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  return (
    <>
      <img
        src={src}
        alt={alt}
        style={{ ...thumbnailStyle, cursor: "pointer" }}
        onClick={handleImageClick}
        className={className}
      />

      {isFullScreen && (
        <div className="fullscreen-overlay" onClick={handleCloseFullScreen}>
          <div className="close-button" onClick={handleCloseFullScreen}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <img src={src} alt={alt} className="fullscreen-image" />
        </div>
      )}
    </>
  );
};

export default FullscreenImage;

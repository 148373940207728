import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../constant/Pagination";
import TableLoaderEffect from "../../../constant/TableLoader";
import { getReturnItemsApi } from "../../../../services/UserService";
import FullscreenImage from "../../../constant/FullscreenImage";

const ReturnableItems = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const recordsPage = 25;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getReturnItemsApi(currentPage, recordsPage, id);
        const resp_data = resp.data.data;
        setData(resp_data);
        setTotal(resp.data.total || 0); 
      } catch (error) {
        console.error("Error fetching gate pass details:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, recordsPage, id]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
};

  return (
    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
      <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
      <table
                  id="empoloyees-tblwrapper"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Photo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <TableLoaderEffect colLength={6} />
                    ) : (
                      data.map((item) => (
                        item.returnble_items?.map((itemreturn, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className="text-primary fw-bold c-pointer"
                              onClick={() => navigate(`/gatepass/${itemreturn?.id}`)}
                            >
                              {itemreturn?.item_name}
                            </span>
                          </td>
                          <td>{itemreturn?.description}</td>
                          <td>
                          <FullscreenImage src={`${process.env.REACT_APP_BASE_URL}${itemreturn?.item_photo}`} alt="Item Image" thumbnailStyle={{
                                height: "30px",
                                width: "30px",
                                objectFit: "cover",
                              }}
                             />
                           </td>
                        </tr>
                      ))
                    ))
                  )}
                  </tbody>
                </table>

        {isLoading ? (
          <div style={{ height: "4rem" }}></div>
        ) : (
          <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
      />
        )}
      </div>
    </div>
  );
};

export default ReturnableItems;

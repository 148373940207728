
  export const getUserNameIcon = (fullName) => {
    const nameArray = fullName.split(" ");
    const firstNameInitial = nameArray[0].charAt(0);
    const lastNameInitial =
      nameArray.length > 1 ? nameArray[nameArray.length - 1].charAt(0) : "";
    const initials = firstNameInitial + lastNameInitial;
    return initials.toUpperCase(); // Convert to uppercase if needed
  };
  
  
import React, { useState } from "react";
import { approveGatepassApi } from "../../../services/GatepassService";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const ApproveModal = ({
  user,
  gatepass_id,
  gatepass_number,
  approvalItem,
  handleAfterApproved,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("Approved");
  const [rejectedReason, setRejectedReason] = useState("");

  const toggleModal = (show) => {
    setShowModal(show);
  };

  const handlestatus = (e) => {
    setApprovalStatus(e.target.value);
  };
  const isButtonClickable = user?.id === approvalItem?.officer?.id;

  const handleApprove = async () => {
    if (isSubmiting || !isButtonClickable){
      return
    }
    try {
      setIsSubmiting(true)
      const payload = { id: approvalItem.id, gatepass_id, status:approvalStatus };
      if (approvalStatus === "Rejected") {
        payload.rejected_reason = rejectedReason;
      }
      const response = await approveGatepassApi(payload);
      const { message, data } = response.data;
      handleAfterApproved(data);
      toast.success(message);
      toggleModal(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally{
      setIsSubmiting(false)
    }
  };


  return (
    <>
      <span
        className={`badge light border-0 badge-warning badge-sm ms-2 mb-1 ${
          isButtonClickable ? "cursor-pointer" : "disabled"
        }`}
        onClick={() => isButtonClickable && toggleModal(true)}
        style={{ fontSize: "0.9rem" }}
      >
        Pending
      </span>
      <Modal
        className="modal fade"
        id="exampleModal3"
        show={showModal}
        onHide={() => toggleModal(false)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4>
              <strong>
                Approve Gatepass{" "}
                <span className="text-primary">{gatepass_number || ""}</span>
              </strong>
            </h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal(false)}
            ></button>
          </div>

          <div className="modal-body p-4">
            <div className="form-group mb-3">
              <label className="form-lable me-4">Status: </label>
              <div className="form-check custom-checkbox mb-2 form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="ApprovedRadio"
                  value="Approved"
                  name="status"
                  onChange={handlestatus}
                  checked={approvalStatus === "Approved"}
                />
                <label className="form-check-label" htmlFor="ApprovedRadio">
                  Approved
                </label>
              </div>

              <div className="form-check custom-checkbox mb-2 form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="RejectedRadio"
                  value="Rejected"
                  name="status"
                  onChange={handlestatus}
                  checked={approvalStatus === "Rejected"}
                />
                <label className="form-check-label" htmlFor="RejectedRadio">
                  Rejected
                </label>
              </div>
            </div>

            {approvalStatus === "Rejected" && (
              <div className="form-group mb-3">
                <label htmlFor="form-label">Rejected Reason</label>
                <textarea
                  className="form-control"
                  name="rejectedReason"
                  onChange={(e) => setRejectedReason(e.target.value)}
                  value={rejectedReason}
                  placeholder="Enter Rejected Reason"
                ></textarea>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-outline-primary btn-sm me-1"
                onClick={handleApprove}
                disabled={!isButtonClickable || isSubmiting}
              >
                {approvalStatus === "Approved" ? isSubmiting ? "Approving..." : "Approve" : isSubmiting ? "Rejecting..." : "Reject"}
              </button>
              <button
                className="btn btn-sm btn-danger ms-2"
                onClick={() => toggleModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ApproveModal);

import React, { useState, useEffect, useRef } from "react";

const Captcha = ({ onGenerate }) => {
  const canvasRef = useRef(null);
  // Generate random CAPTCHA code
  const generateCaptcha = () => {
    const captchaLength = 6;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captchaCode = "";

    for (let i = 0; i < captchaLength; i++) {
      captchaCode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    onGenerate(captchaCode);
    drawCaptcha(captchaCode);
  };

  // Draw CAPTCHA on canvas
  const drawCaptcha = (captchaCode) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Background color
    ctx.fillStyle = "#f0f0f0";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Noise lines
    for (let i = 0; i < 5; i++) {
      ctx.strokeStyle = "#514f4f";
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.stroke();
    }

    // CAPTCHA text
    ctx.font = "30px Arial";
    ctx.fillStyle = getRandomColor();
    ctx.fillText(captchaCode, 35, 35);
  };

  // Random color generator
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Generate CAPTCHA on component mount
  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <div className="captcha-container">
      <div className="d-flex align-items-center">
        <canvas ref={canvasRef} style={{backgroundColor: '#f0f0f0', 
            border: '1px solid #ccc', display: 'block', borderRadius: '5px'}} width="200" height="50"></canvas>
        <button
          type="button"
          className="btn btn-sm btn-outline-primary btn-circle ms-3"
          onClick={generateCaptcha}
        >
          <i className="fa-solid fa-rotate"></i>
        </button>
      </div>
    </div>
  );
};

export default Captcha;

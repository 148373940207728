import React, { useState, useEffect, useCallback } from "react";
import { getGatepassListApi } from "../../../services/GatepassService";
import { CancelRequest } from "../../../services/AxiosInstance";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../constant/Pagination";
import TableLoaderEffect from '../../constant/TableLoader';
import { connect } from "react-redux";
import DebounceSearchInput from "../../constant/DebounceSearchInput";
import { formatDateRange } from "../../../utils";
import ApprovalInfo from "./ApprovalInfoModal";

const GatepassPage = ({ userRole, user }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const recordsPage = 25;

    const navigate = useNavigate();

    const fetchData = useCallback((page, query) => {
        setIsLoading(true);
        getGatepassListApi(page, recordsPage, query)
            .then((resp) => {
                setData(resp.data.data);
                setTotal(resp.data.total);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error.response?.data?.message || "Error fetching data");
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData(currentPage, searchQuery);
    }, [currentPage, searchQuery, fetchData]);

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const handleSearchChange = (value) => {
        if (value.length > 0) CancelRequest();
        setSearchQuery(value);
        setCurrentPage(1)
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                <div className="d-flex justify-content-between text-wrap align-items-center p-3">
                                    <h4 className="heading mb-0">Gatepass List</h4>
                                    <div className='d-flex'>
                                        <div className='form-group me-3'>
                                            <DebounceSearchInput handleSearchChange={handleSearchChange} />
                                        </div>
                                        {userRole === "Visitor" && (
                                            <button className="btn btn-sm btn-primary" onClick={() => navigate('/apply-gatepass')}>Apply Gatepass</button>
                                        )}
                                    </div>
                                </div>

                                <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                    <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                        <thead>
                                            <tr>
                                                <th>Gatepass Number</th>
                                                {userRole !== "Visitor" &&
                                                    <th>Visitor</th>
                                                }
                                                <th>Visiting Date</th>
                                                <th>Department</th>
                                                <th>Officer To Meet </th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? <TableLoaderEffect colLength={9} /> : data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span className="text-primary fw-bold c-pointer"
                                                            onClick={() => navigate(`/gatepass/${item.id}`)}>{item.gatepass_number}</span>
                                                    </td>
                                                    {userRole !== "Visitor" &&
                                                        <td><Link to={`/visitor/${item.visitor.id}`} >{item.visitor.name}</Link></td>
                                                    }
                                                    <td>{formatDateRange(item.visiting_start_date, item.visiting_end_date)}</td>
                                                    <td>{item.approval[0].officer.department} {item.approval.length > 1 && (
                                                        <ApprovalInfo approvalList={item.approval} />
                                                    )}</td>
                                                    <td>{item.approval[0].officer.name}</td>
                                                    <td>{item.approval[0].status === "Approved" ? <span className='badge light border-0 badge-success badge-sm'>Approved</span> :
                                                        item.approval[0].status === "Pending" ? <span className='badge light border-0 badge-warning badge-sm'>Pending</span> : <span className='badge light border-0 badge-danger badge-sm'>Rejected</span>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                        currentPage={currentPage}
                                        recordsPage={recordsPage}
                                        dataLength={total}
                                        handlePagination={handlePagination}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
const mapStateToProps = (state) => ({
    userRole: state.auth.user?.role || "Visitor",
    user: state.auth.user,
});

export default connect(mapStateToProps)(GatepassPage);

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../constant/Pagination";
import TableLoaderEffect from "../../../constant/TableLoader";
import { getVisitingLogApi } from "../../../../services/UserService";

const VisitorLog = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const recordsPage = 25;
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getVisitingLogApi(currentPage, recordsPage, id);
        const resp_data = resp.data.data;
        setData(resp_data);
        setTotal(resp.data.total || 0); 
      } catch (error) {
        console.error("Error fetching gate pass details:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, recordsPage, id]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
};

  return (
    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
      <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
        <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
          <thead>
            <tr>
              <th>Gatepass Number</th>
              <th>Gate</th>
              <th>Officer To Meet</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoaderEffect colLength={5} />
            ) : (
              data && (
                  <tr>
                    <td>{data.gatepass?.gatepass_number}</td>
                    <td>{data?.gate?.name}</td>
                    <td>{data.officer_to_meet?.name}</td>
                    <td>
                              <span  className={`badge light border-0 ${
                              data.status
                                ? "badge-success"
                                : "badge-danger"
                            } badge-sm ms-2`}>
                            {data.status ? "Entry" : "Exit"}</span></td>
                  </tr>
              )
            )}
          </tbody>
        </table>

        {isLoading ? (
          <div style={{ height: "4rem" }}></div>
        ) : (
          <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
      />
        )}
      </div>
    </div>
  );
};

export default VisitorLog;

import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import GatePassDetails from "./GatePassDetails";
import CoVisitor from "./CoVisitor";
import ReturnableItems from "./ReturnableItems";
import VisitorLog from "./visitorLog";

const VisitorTab = () => {
  const [activeKey, setActiveKey] = useState("gatepassDetails");

  return (
    <div className="card">
      <Tab.Container
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k)}
      >
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="gatepassDetails" className="tabnav p-3">
              <strong>Gate Pass Details</strong>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="coVisitor" className="tabnav p-3">
              <strong>Co-Visitor</strong>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="returnableItems" className="tabnav p-3">
              <strong>Returnable Items</strong>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="visitorLog" className="tabnav p-3">
              <strong>Visiting Log</strong>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="gatepassDetails">
            {activeKey === "gatepassDetails" && <GatePassDetails />}
          </Tab.Pane>
          <Tab.Pane eventKey="coVisitor">
            {activeKey === "coVisitor" && <CoVisitor />}
          </Tab.Pane>
          <Tab.Pane eventKey="returnableItems">
            {activeKey === "returnableItems" && <ReturnableItems />}
          </Tab.Pane>
           <Tab.Pane eventKey="visitorLog">
            {activeKey === "visitorLog" && <VisitorLog />}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default VisitorTab;

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { getofficerMostVisitor } from '../../../../services/DashboardService';
import { Placeholder } from 'react-bootstrap';

const OfficerMostVisitor = () => {
  const [dataset, setDataset] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getofficerMostVisitor(); 
        if (isMounted) {
          setDataset(resp.data.results);
        }
      } catch (error) {
        if (isMounted) {
          console.log(error.response ? error.response.data.message : error);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false; 
    };
  }, []);

  const getSolidColors = (labels) => {
    const colors = [
      '#FF4560', 
      '#008FFB', 
      '#00E396', 
      '#775DD0', 
      '#FEB019', 
      '#FF66C3', 
      '#A9A9A9',
      '#D9A400', 
      '#D50000', 
      '#1E88E5', 
    ];
        return colors.slice(0, labels?.length);
  };

  const chartData = {
    series: dataset?.datasets[0]?.data,
    options: {
      chart: {
        type: 'polarArea',
        height: 250,
      },
      labels: dataset?.labels,
      colors: getSolidColors(dataset?.labels),
      tooltip: {
        y: {
          formatter: (val) => `${val} Visitors`,
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        floating: false,
        verticalAlign: 'bottom',
        horizontalAlign: 'center',
        labels: {
          colors: '#333',
        },
      },
    },
  };

  return (
    <>
    <div className="card"> 
      {isLoading ? (
        <div className="card-body">
        <div className="w-100">
          <Placeholder animation="glow">
            <Placeholder
              xs={12}
              style={{ height: "200px" }}
              className="rounded"
            />
          </Placeholder>
        </div>
      </div>
      ) : dataset ? (
        <div className="card-body">
          <h5 className="card-title">Visitor Count Overview</h5>
          <p className="card-text">
            This polar area chart provides an overview of the visitor counts for each officer.
          </p>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="polarArea"
            height={350}
          />
        </div>
      ) : (
        <div>No Data Available</div>
      )}
    </div>
    </>
  );
};

export default OfficerMostVisitor;

import React, { useState } from 'react';
import { Modal, Button, } from 'react-bootstrap';
import { formatDateTime } from '../../../utils';
const ApprovalInfo = ({ approvalList = [] }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>

      <Button className='btn bg-transparent border-0 text-primary fs-6' onClick={handleShow}>
        +{approvalList.length - 1}  More
      </Button>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Approval Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled px-3">
            {approvalList.map((item, index) => (
              <li
                className="mb-3 d-flex align-items-center justify-content-between"
                key={index}
              >
                <div className="d-flex flex-column">
                  <span className="fw-bold fs-5 mb-1">
                    {item.officer.department}
                  </span>
                  <span className="fw-normal">
                    {item.officer.name}{" "}
                    <span className="fw-semibold">
                      ({item.officer.designation})
                    </span>
                  </span>
                </div>
                <div className='d-flex flex-column'>
                  {item?.status === "Approved" ? (
                    <span className="badge light border-0 badge-success ms-2 mb-1">
                      Approved
                    </span>
                  ) : item?.status === "Pending" ? (
                    <span className="badge light border-0 badge-warning ms-2 mb-1">
                      Pending
                    </span>
                  ) : (
                    <span className="badge light border-0 badge-danger ms-2 mb-1">
                      Rejected
                    </span>
                  )}
                  <small>{formatDateTime(item.last_update)}</small>
                </div>

              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApprovalInfo;

import React, { useState } from "react";
import { createGateApi } from "../../../services/ProductService";
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DynamicSelectInput from "../../constant/SelectInput";
import * as yup from "yup";

const GateAddSchema = yup.object().shape({
  gate_location: yup.string().required("Gate Location is required"),
  department_id: yup.string().when("gate_location", ([val], schema) => {
    return val === "Department"
      ? schema.required("Please Select Department")
      : schema.notRequired();
  }),
  name: yup.string().required("Gate Name is required"),
  gate_type: yup.string().required("Gate Type is required"),
});

const GateAddModal = ({ handleAfterCreate }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(GateAddSchema),
    defaultValues: {
      gate_location: "CISF",
      name: "",
      department_id: null,
      gate_type: "Entry",
    },
  });

  // Use watch to keep track of the gate_type field
  const selectedGateType = watch("gate_type");
  const selectedGateLocation = watch("gate_location");

  const toggleModal = () => {
    if (showModal) reset();
    setShowModal(!showModal);
  };

  const handleSelectChange = (name, value, _) => {
    setValue(name, value);
    clearErrors(name)
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const resp = await createGateApi(data);
      handleAfterCreate(resp.data.data);
      toast.success(resp.data.message);
      toggleModal();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error creating gate");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button className="btn btn-primary ms-1" onClick={toggleModal}>
        Add Gate
      </button>

      <Modal show={showModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Gate</h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label>Gate Location</label>
                <br />
                <div className="form-check custom-checkbox mb-2 form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="CISFRadio"
                    value="CISF"
                    {...register("gate_location")}
                    checked={selectedGateLocation === "CISF"}
                  />
                  <label className="form-check-label" htmlFor="CISFRadio">
                    CISF Gate
                  </label>
                </div>
                <div className="form-check custom-checkbox mb-2 form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="DepartmentRadio"
                    value="Department"
                    {...register("gate_location")}
                    checked={selectedGateLocation === "Department"}
                  />
                  <label className="form-check-label" htmlFor="DepartmentRadio">
                    Department Gate
                  </label>
                </div>
                <p className="text-danger">{errors.gate_location?.message}</p>
              </div>

              {selectedGateLocation === "Department" && (
                <div className="form-group">
                  <DynamicSelectInput
                    parentClassName="mb-0"
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/department"
                    name={"department_id"}
                    label={"Department"}
                    labelHint={false}
                    id={"department_id"}
                    placeholder={"Select Department"}
                    isRequired={true}
                  />
                  <p className="text-danger">{errors.department_id?.message}</p>
                </div>
              )}

              <div className="form-group">
                <label>Gate Name</label>
                <input
                  type="text"
                  placeholder="Enter gate name"
                  className="form-control"
                  {...register("name")}
                />
                <p className="text-danger">{errors.name?.message}</p>
              </div>
              <div className="form-group">
                <label>Gate Type</label>
                <br />
                <div className="form-check custom-checkbox mb-2 form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="EntryRadio"
                    value="Entry"
                    {...register("gate_type")}
                    checked={selectedGateType === "Entry"}
                  />
                  <label className="form-check-label" htmlFor="EntryRadio">
                    Entry
                  </label>
                </div>
                <div className="form-check custom-checkbox mb-2 form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="ExitRadio"
                    value="Exit"
                    {...register("gate_type")}
                    checked={selectedGateType === "Exit"}
                  />
                  <label className="form-check-label" htmlFor="ExitRadio">
                    Exit
                  </label>
                </div>
                <p className="text-danger">{errors.gate_type?.message}</p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner size="sm" /> Creating...
                    </>
                  ) : (
                    "Create"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ms-3"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GateAddModal;

import axiosInstance from './AxiosInstance';
const userUrl = "api/user"
const visitorUrl = "api/visitor"
// user
export function getUserListApi(page, page_size, query) {
    return axiosInstance.get(`${userUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getUserApi(id) {
    return axiosInstance.get(`${userUrl}/${id}`);
}

export function createUserApi(data) {
    return axiosInstance.post(userUrl, data);
}

export function updateUserApi(data) {
    return axiosInstance.put(userUrl, data);
}


export function getVisitorListApi(page, page_size, query) {
    return axiosInstance.get(`${visitorUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getVisitorApi(id) {
    return axiosInstance.get(`${visitorUrl}/${id}`);
}

export function getVisitorGatepassApi(page, page_size, id) {
    return axiosInstance.get(`api/visitor/gatepass/${id}?page=${page}&page_size=${page_size}`);
}
export function getCOVisitorApi(page, page_size, id) {
    return axiosInstance.get(`api/visitor/co_visitor/${id}?page=${page}&page_size=${page_size}`);
}
export function getReturnItemsApi(page, page_size, id) {
    return axiosInstance.get(`api/visitor/returnable_items/${id}?page=${page}&page_size=${page_size}`);
}

export function createVisitorApi(data) {
    return axiosInstance.post(visitorUrl, data);
}

export function updateVisitorApi(data) {
    return axiosInstance.put(visitorUrl, data);
}

export function getVisitingLogListApi(page, page_size, query) {
    return axiosInstance.get(`api/visiting-log?page=${page}&page_size=${page_size}&query=${query}`);
}
export function getVisitingLogApi(page, page_size, id) {
    return axiosInstance.get(`api/visiting-log/${id}?page=${page}&page_size=${page_size}`);
}

export function getReturnableItemListApi(page, page_size, query) {
    return axiosInstance.get(`api/returnable_items?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getCoVisitorListApi(page, page_size, query) {
    return axiosInstance.get(`api/co_visitor?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getExstingCovisitorListApi() {
    return axiosInstance.get('api/existing_covisitor');
}

export function getExstingReturnableItemsListApi() {
    return axiosInstance.get('api/existing_returnable_items');
}

export function getExstingVehicleListAPi() {
    return axiosInstance.get('api/existing_vehicle');
}

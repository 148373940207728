import React, { useState, useEffect, useRef } from "react";
import ChooseExistingDataModal from "./ChooseExistingDataModal";
const VehicleSubForm = ({ register, setValue, clearErrors, errors, watch }) => {
  const [driverProfilePhotoPreviews, setDriverProfilePhotoPreviews] =
    useState("");
  const [driverAadhaarPhotoPreviews, setDriverAadhaarPhotoPreviews] =
    useState("");

  const driverProfilePhotoRef = useRef(null);
  const driverAadhaarPhotoRef = useRef(null);

  useEffect(() => {
    if (errors.driver_profile_photo && driverProfilePhotoRef.current) {
      driverProfilePhotoRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (errors.driver_aadhaar_photo && driverAadhaarPhotoRef.current) {
      driverAadhaarPhotoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors]);

  const handleDriverProfilePhotoChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDriverProfilePhotoPreviews(reader.result);
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  const handleDriverAadhaarPhotoChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDriverAadhaarPhotoPreviews(reader.result);
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  const handleVehicleSelect = (data) => {
    setValue("vehicle_id", data.id);
    setValue("vehicle_number", data.vehicle_number);
    setValue("driver_dl_number", data.driving_licence_number);
    setValue("drive_type", data.is_self_drive ? "Self" : "Other");
    if (!data.is_self_drive) {
      setValue("driver_name", data.driver_name);
      setValue("driver_aadhaar_number", data.aadhaar_number);
      setValue("driver_profile_photo", data.profile_photo);
      setValue("driver_aadhaar_photo", data.aadhaar_photo);
    }
    setDriverProfilePhotoPreviews(
      `${process.env.REACT_APP_BASE_URL}${data.profile_photo}`
    );
    setDriverAadhaarPhotoPreviews(
      `${process.env.REACT_APP_BASE_URL}${data.aadhaar_photo}`
    );
    clearErrors("vehicle_id");
    clearErrors("vehicle_number");
    clearErrors("driver_dl_number");
    clearErrors("drive_type");
    clearErrors("driver_name");
    clearErrors("driver_aadhaar_number");
    clearErrors("driver_profile_photo");
    clearErrors("driver_aadhaar_photo");
  };
  const handleVehicleClear = () => {
    setValue("vehicle_id", "");
    setValue("vehicle_number", "");
    setValue("driver_dl_number", "");
    setValue("drive_type", "");
    setValue("driver_name", "");
    setValue("driver_aadhaar_number", "");
    setValue("driver_profile_photo", null);
    setValue("driver_aadhaar_photo", null);
    setDriverProfilePhotoPreviews("");
    setDriverAadhaarPhotoPreviews("");
  };

  const isVehicleSelect = watch(`vehicle_id`)?.startsWith("vve_") || false;

  return (
    <div>
      <h4 className="my-3">Vehicle's Informations</h4>
      {watch("vehicle_available") === "YES" ? (
        <p className="text-warning">
          <strong>
            Subject to verification by{" "}
            <a
              href="https://www.cisf.gov.in/"
              className="text-underline text-primary"
              rel="noopener noreferrer"
              target="_blank"
            >
              CISF
            </a>
            .{" "}
          </strong>
          Valid Insurance, Pollution Certificate and Vehicle Registration to be
          verified
        </p>
      ) : null}
      <div className="row">
        <div className="form-group col-lg-5 col-md-6">
          <label className="form-label">Vehicle Available </label>
          <br />
          <div className="form-check custom-checkbox mb-2 form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="YESRadio"
              value="YES"
              disabled={isVehicleSelect}
              {...register(`vehicle_available`, {
                required: true,
              })}
            />
            <label className="form-check-label" htmlFor="YESRadio">
              YES
            </label>
          </div>
          <div className="form-check custom-checkbox mb-2 form-check-inline">
            <input
              type="radio"
              className="form-check-input"
              id="NORadio"
              disabled={isVehicleSelect}
              value="NO"
              {...register("vehicle_available", {
                required: true,
              })}
            />
            <label className="form-check-label" htmlFor="NORadio">
              NO
            </label>
          </div>
          {watch("vehicle_available") === "YES" && (
            <div className="d-inline">
              {isVehicleSelect ? (
                <span
                  className="c-pointer text-danger rounded border p-2 border-danger"
                  onClick={() => handleVehicleClear()}
                >
                  Clear Existing Vehicle{" "}
                  <i className="fa-solid fa-delete-left ms-2"></i>
                </span>
              ) : (
                <ChooseExistingDataModal
                  handleAfterSelect={handleVehicleSelect}
                  selectType="Vehicle"
                  excludeId={[]}
                />
              )}
            </div>
          )}
          {errors.vehicle_available && (
            <p className="text-danger">{errors.vehicle_available?.message}</p>
          )}
        </div>
        {watch("vehicle_available") === "YES" && (
          <>
            <input
              type="hidden"
              {...register(`vehicle_id`, {
                required: false,
              })}
            />
            <div className="form-group col-lg-4 col-md-6 mb-3">
              <label className="form-label">Vehicle Number</label>
              <input
                className="form-control text-uppercase"
                placeholder="Enter vehicle number"
                {...register(`vehicle_number`, {
                  required: true,
                })}
                readOnly={isVehicleSelect}
              />
              {errors.vehicle_number && (
                <p className="text-danger">{errors.vehicle_number?.message}</p>
              )}
            </div>

            <div className="form-group col-lg-3 col-md-6 mb-3">
              <label className="form-label">Drive Type </label>
              <br />
              <div className="form-check custom-checkbox mb-2 form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="SelfRadio"
                  disabled={isVehicleSelect}
                  value="Self"
                  {...register(`drive_type`, {
                    required: true,
                  })}
                />
                <label className="form-check-label" htmlFor="SelfRadio">
                  Self Drive
                </label>
              </div>
              <div className="form-check custom-checkbox mb-2 form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="OtherRadio"
                  value="Other"
                  disabled={isVehicleSelect}
                  {...register("drive_type", {
                    required: true,
                  })}
                />
                <label className="form-check-label" htmlFor="OtherRadio">
                  Other
                </label>
              </div>
              {errors.drive_type && (
                <p className="text-danger">Select Drive Type</p>
              )}
            </div>

            {watch("drive_type") && (
              <div className="form-group col-lg-4 col-md-6 mb-3">
                <label className="form-label">
                  {watch("drive_type") === "Self" ? "" : "Driver"} DL Number
                </label>
                <input
                  className="form-control text-uppercase"
                  readOnly={isVehicleSelect}
                  placeholder={`Enter ${
                    watch("drive_type") === "Self" ? "your" : "driver"
                  } DL number`}
                  {...register(`driver_dl_number`, {
                    required: true,
                  })}
                />
                {errors.driver_dl_number && (
                  <p className="text-danger">
                    {errors.driver_dl_number?.message}
                  </p>
                )}
              </div>
            )}

            {watch("drive_type") === "Other" && (
              <>
                <div className="form-group col-lg-4 col-md-6 mb-3">
                  <label className="form-label">Driver Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter driver name"
                    readOnly={isVehicleSelect}
                    {...register(`driver_name`, {
                      required: true,
                    })}
                  />
                  {errors.driver_name && (
                    <p className="text-danger">{errors.driver_name?.message}</p>
                  )}
                </div>

                <div className="form-group col-lg-4 col-md-6 mb-3">
                  <label className="form-label">Driver Aadhaar Number</label>
                  <input
                    className="form-control"
                    placeholder="Enter driver aadhaar number"
                    readOnly={isVehicleSelect}
                    {...register(`driver_aadhaar_number`, {
                      required: true,
                    })}
                  />
                  {errors.driver_aadhaar_number && (
                    <p className="text-danger">
                      {errors.driver_aadhaar_number?.message}
                    </p>
                  )}
                </div>

                <div
                  className="form-group col-md-6 mb-3"
                  ref={driverProfilePhotoRef}
                >
                  <label className="form-label">
                    Driver Profile Photo (JPG/PNG)
                  </label>
                  <div
                    className="file-upload-box"
                    onClick={() => {
                      if (!isVehicleSelect) {
                        document
                          .getElementById("driverProfilePhotoInput")
                          .click();
                      }
                    }}
                  >
                    {driverProfilePhotoPreviews ? (
                      <img
                        src={driverProfilePhotoPreviews}
                        alt="Profile Preview"
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={"driverProfilePhotoInput"}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register("driver_profile_photo", {
                      required: true,
                    })}
                    onChange={handleDriverProfilePhotoChange}
                  />
                  {errors.driver_profile_photo && (
                    <p className="text-danger">
                      {errors.driver_profile_photo?.message}
                    </p>
                  )}
                </div>

                {/* driver aadhaar photo */}
                <div
                  className="form-group col-md-6 mb-3"
                  ref={driverAadhaarPhotoRef}
                >
                  <label className="form-label">
                    Driver Aadhaar Photo (JPG/PNG)
                  </label>
                  <div
                    className="file-upload-box"
                    onClick={() => {
                      if (!isVehicleSelect) {
                        document
                          .getElementById("driverAadhaarPhotoInput")
                          .click();
                      }
                    }}
                  >
                    {driverAadhaarPhotoPreviews ? (
                      <img
                        src={driverAadhaarPhotoPreviews}
                        alt="Aadhaar Preview"
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={"driverAadhaarPhotoInput"}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register("driver_aadhaar_photo", {
                      required: true,
                    })}
                    onChange={handleDriverAadhaarPhotoChange}
                  />
                  {errors.driver_aadhaar_photo && (
                    <p className="text-danger">
                      {errors.driver_aadhaar_photo?.message}
                    </p>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VehicleSubForm;

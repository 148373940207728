import React, { useEffect, useRef } from "react";
import { useFieldArray } from "react-hook-form";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../../constant/SelectInput";

const VisitingDepartmentSubForm = ({
  control,
  setValue,
  clearErrors,
  errors,
  watch,
  departemntOptions,
}) => {
  const {
    fields: visitingDepartment,
    append: appendVisitingDepartment,
    remove: removeVisitingDepartment,
  } = useFieldArray({
    control,
    name: "visitingDepartment",
  });

  const departmentRefs = useRef([]);

  const handleSelectChange = (name, value) => {
    setValue(name, value);
    clearErrors(name);
  };

  // Scroll into view when there's an error
  useEffect(() => {
    visitingDepartment.forEach((_, index) => {
      if (errors.visitingDepartment?.[index]?.department && departmentRefs.current[index]) {
        departmentRefs.current[index].scrollIntoView({ behavior: "smooth" });
      }
      if (errors.visitingDepartment?.[index]?.officer && departmentRefs.current[index]) {
        departmentRefs.current[index].scrollIntoView({ behavior: "smooth" });
      }
    });
  }, [errors, visitingDepartment]);

  return (
    <div>
      <div className="d-flex align-items-center py-3">
        <h4 className="mb-0 me-2">Visiting Department</h4>
        {visitingDepartment.length < 6 && (
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={() => appendVisitingDepartment({})}
          >
            Add Department
          </button>
        )}
      </div>

      {/* department List */}
      <div className="row">
        {visitingDepartment.map((vd, index) => (
          <div
            key={index}
            className="col-md-4 col-sm-12 mb-2"
            ref={(el) => (departmentRefs.current[index] = el)} // Assign the ref for each department field
          >
            <div className="border rounded bg-light-gray">
              <div className="d-flex align-items-center justify-content-between border-bottom p-2">
                <h6 className="mb-0 pb-1">Department {index + 1}</h6>
                {visitingDepartment.length > 1 &&
                  visitingDepartment.length - 1 === index && (
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm btn-circle"
                      onClick={() => {
                        removeVisitingDepartment(index);
                      }}
                    >
                      <i className="fa fa-solid fa-x"></i>
                    </button>
                  )}
              </div>
              <div className="px-2 pt-2">
                <div className="form-group mb-3">
                  <StaticSelectInput
                    parentClassName="mb-0"
                    setParentInputValue={handleSelectChange}
                    optionsList={departemntOptions.filter(
                      (dep) =>
                        !watch("visitingDepartment")
                          .filter(
                            (vd) =>
                              vd.department !==
                              watch(`visitingDepartment.${index}.department`)
                          )
                          .map((item) => item.department)
                          .includes(dep.value)
                    )}
                    label={"Visiting Department"}
                    name={`visitingDepartment.${index}.department`}
                    id={`visitingDepartment.${index}.department`}
                    labelHint={false}
                    placeholder={"Select Visiting Department"}
                    isRequired={true}
                  />
                  {errors.visitingDepartment?.[index]?.department && (
                    <p className="text-danger">
                      {errors.visitingDepartment[index].department?.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  {watch(`visitingDepartment.${index}.department`) ? (
                    <DynamicSelectInput
                      parentClassName="mb-0"
                      setParentInputValue={handleSelectChange}
                      endpoint_name={`search/officer_to_meet/${watch(`visitingDepartment.${index}.department`)}`}
                      label={"Officer to Meet"}
                      labelHint={false}
                      name={`visitingDepartment.${index}.officer`}
                      id={`visitingDepartment.${index}.officer`}
                      placeholder={"Select Officer To Meet"}
                      isRequired={true}
                    />
                  ) : (
                    <StaticSelectInput
                      parentClassName="mb-0"
                      setParentInputValue={handleSelectChange}
                      optionsList={[]}
                      label={"Officer to Meet"}
                      labelHint={false}
                      name={`visitingDepartment.${index}.officer`}
                      id={`visitingDepartment.${index}.officer`}
                      placeholder={"Select department first"}
                      isRequired={true}
                    />
                  )}

                  {errors.visitingDepartment?.[index]?.officer && (
                    <p className="text-danger">
                      {errors.visitingDepartment[index].officer?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VisitingDepartmentSubForm;

import { SVGICON } from "../../constant/theme";

export const MenuList = [

    {
        title: 'Dashboard',
        iconStyle: SVGICON.MenuDashboard,
        to: '/dashboard',
        accessRole: ["SuperAdmin", "Admin", "Visitor"]
    },
    {
        title: 'Gatepass',
        iconStyle: SVGICON.MenuReport,
        to: '/gatepass',
        accessRole: ["SuperAdmin", "Admin", "Visitor"]
    },
    {
        title: 'User',
        iconStyle: SVGICON.MenuUsers,
        to: '/user',
        accessRole: ["SuperAdmin"]
    }, 
    {
        title: 'Visitor',
        iconStyle: SVGICON.MenuVisitors,
        to: '/visitor',
        accessRole: ["SuperAdmin", "Admin"]
    },  
    {
        title: 'Gate',
        iconStyle: SVGICON.MenuLayout,
        to: '/gate',
        accessRole: ["SuperAdmin"]
    },
    {
        title: 'Device',
        iconStyle: SVGICON.MenuDevice,
        to: '/device',
        accessRole: ["SuperAdmin"]
    },
    {
        title: 'Visiting Log',
        iconStyle: SVGICON.MenuVisitingLog,
        to: '/visiting-log',
        accessRole: ["SuperAdmin", "Admin"]
    },
    {
        title: 'Dept. & Designation',
        iconStyle: SVGICON.MenuDepartment,
        to: '/department-designation',
        accessRole: ["SuperAdmin"]
    },
    {
        title: 'Co-Visitor',
        iconStyle: SVGICON.MenuCoVisitors,
        to: '/co-visitor',
        accessRole: ["Visitor"]
    },
    {
        title: 'Returnable Items',
        iconStyle: SVGICON.MenuReturnableItem,
        to: '/returnable-items',
        accessRole: ["Visitor"]
    },
]